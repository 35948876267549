import React from 'react';
import MainLayout from '../layouts/mainLayout';

export default () => {
  return (
    <MainLayout withTitle>
      <div className="features-wrapper">
        <div className="container-fluid">
          <div className="teat-bg">
            <div className="section-item def-w-max">
              <div className="row">
                <div className="col-2">&nbsp;</div>
                <div className="col-8">
                  <div className="txt-wrap-sect pdd-rt-50">
                    <h2 className="mgn-bot-30">Shipping Costs</h2>
                    <p>
                      We charge a nominal shipping fee to encourage
                      consolidating your pet's needs into the same delivery, so
                      we can save on costs, packaging, and reduce carbon
                      emissions.
                    </p>
                    <p>
                      For our tailored feeding plan with auto-delivery, the
                      shipping fee is a flat rate of $6.99 per order, regardless
                      of the order size or your location in NZ*. We highly
                      recommend setting up auto-delivery for added convenience,
                      the best value pricing, and cheaper shipping.
                    </p>
                    <p>
                      If you prefer to make one-off orders from our shop, the
                      shipping fee will typically be as follows:
                      <ul>
                        <li>Taste Tester - Free</li>
                        <li>Flea & Worm Treatment - $3.99</li>
                        <li>Treats, Toppers & Toys - $4.99</li>
                        <li>Buddles or Multi-buys - $5.99</li>
                        <li>
                          Dry Dog Food - $8.99 to $12.99 (depending on size)
                        </li>
                      </ul>
                    </p>
                    <p>
                      When you purchase multiple products, you will only be
                      charged for shipping once, based on the largest item in
                      your order. This way, we ensure that you don't incur
                      unnecessary shipping costs.
                    </p>
                    <p>
                      We entrust NZ Post with the courier services for all our
                      deliveries. In the North Island, you can expect overnight
                      delivery, while in the South Island, the delivery
                      timeframe is 2-3 business days under our Economy shipping
                      option.
                    </p>
                    <p className="qnA-text">
                      * Please note that for rural deliveries, an additional
                      charge of $4.53 applies. This charge is imposed by NZ
                      Post, and we pass it on to our customers without any
                      markup. Please also allow an additional 1-2 days for
                      delivery.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
